import React from "react";
import { graphql, Link } from "gatsby";

const IndexPage = ({ data }) => {
  const {
    allDatoCmsBlogpost: { nodes: posts },
  } = data;
  return (
    <main className="max-w-lg mx-auto">
      <h1 className="text-3xl mb-8 text-slate-800">
        Test page — This should be a preview
      </h1>
      <h2 className="text-xl">SSR test</h2>
      <Link className="text-blue-500 underline" to="/ssr">
        SSR doggos
      </Link>
      <div className="mt-10">
        <h2 className="text-xl">Blogposts</h2>
        <p>
          First 5 posts are static, the rest are DSG (Deferred Static
          Generation)
        </p>
        <ul>
          {posts.map(({ title, slug }) => (
            <li>
              <Link className="text-blue-500 underline" to={"/" + slug}>
                {title}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </main>
  );
};

export const query = graphql`
  query IndexPageQuery {
    allDatoCmsBlogpost {
      nodes {
        slug
        title
      }
    }
  }
`;

export default IndexPage;

export const Head = () => <title>Home Page</title>;
